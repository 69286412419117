<template>
  <div>
    <PageMenu :breadcrumbs="breadcrumbs" :page="page">
      <template v-slot:toolbar>
        <b-button :to='{name: "products.packages"}' variant="primary" size="sm">
          Back to Packages
        </b-button>
      </template>
    </PageMenu>

    <div class="page-with-menu">
      <b-card class="mb-5">
        <b-card-text>
          <PackageForm/>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
    components: {
      PageMenu: () => import('@/common/components/PageMenu'),
      PackageForm: () => import('./../../components/Package.Form'),
    },
    data(){
      return {
        page: {
            title: "New Package",
        },
        breadcrumbs: [
            {
                title: "Dashboard",
                url: this.$router.resolve({name: "app.dashboard"}).href
            },
            {
                title: "Product Management",
                url: this.$router.resolve({name: "products"}).href
            },
            {
                title: "Packages",
                url: this.$router.resolve({name: "products.packages"}).href
            },
        ],
        product: null
      }
    },
    methods: {

    },
    mounted(){
      this.$emit('set-sidebar',{active: "products"});
    },
};
</script>
